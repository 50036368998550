<template>
  <PageBlock
    :block-title="blockTitle"
    :block-columns="blockColumns"
    :grid="[1, 8, 12]"
    data-brightness="dark"
  >
    <div class="contact--info">
      <slot></slot>
    </div>
    <div class="contact--map">
      <GmapMap
        :center="octopus_ink.position"
        :zoom="13"
        :options="{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUi: false,
          styles: mapStyle,
        }"
      >
        <GmapInfoWindow
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        >
          <slot name="info-window"></slot>
        </GmapInfoWindow>
        <GmapMarker
          :icon="{
            url: require('@/assets/map-marker.png'),
            scaledSize: google && new google.maps.Size(35, 45),
          }"
          :position="octopus_ink.position"
          :clickable="true"
          :draggable="false"
          @click="toggleInfoWindow(octopus_ink)"
        />
      </GmapMap>
    </div>
    <template v-slot:secondary>
      <div class="anim">
        <p class="column-label">
          {{ blockColumns[1] }}
        </p>

        <form @submit.prevent="submit" ref="contactForm" class="form">
          <div class="form-group">
            <label for="your-name">
              Name
              <abbr title="Required">*</abbr>
            </label>
            <input id="your-name" type="text" v-model="form.name" required />
          </div>
          <div class="form-group">
            <label for="your-email">
              Email
              <abbr title="Required">*</abbr>
            </label>
            <input id="your-email" type="email" v-model="form.email" required />
          </div>
          <div class="form-group form-group--wide">
            <label for="your-message">
              Message
              <abbr title="Required">*</abbr>
            </label>
            <textarea
              id="your-message"
              v-model="form.message"
              required
              rows="4"
            ></textarea>
          </div>
          <div class="form-group--footer">
            <div v-if="form.email || form.name || form.message">
              <vue-recaptcha
                ref="recaptcha"
                @verify="onVerify"
                :sitekey="recaptcha.site_key"
              >
              </vue-recaptcha>
              <p v-if="form.robot" class="error">
                Thanks! you have been validated as a real human :)
              </p>
            </div>

            <button
              :disabled="
                form.sending ||
                !form.robot ||
                (!form.email && !form.name && !form.message)
              "
              type="submit"
              class="button button--outline"
            >
              {{ contactButtonText }}
            </button>

            <p v-if="contactFormMessage">
              {{ contactFormMessage }}
            </p>
          </div>
        </form>
      </div>
    </template>
  </PageBlock>
</template>

<script>
import PageBlock from '@/components/PageBlock.vue';
import { gmapApi } from 'vue2-google-maps';
import { VueRecaptcha } from 'vue-recaptcha';

import axios from 'axios';

export default {
  name: 'ContactBlock',
  components: {
    PageBlock,
    VueRecaptcha,
  },
  props: {
    blockTitle: {
      type: String,
      default: 'Contact',
    },
    blockColumns: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    google: gmapApi,
  },
  data() {
    return {
      recaptcha: {
        site_key: '6Ld54rYUAAAAAIdDdTbvc261cJgyptg4U4nSC2O6',
      },
      form: {
        robot: false,
        sending: false,
      },
      contactButtonText: 'Send',
      contactFormMessage: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -50,
        },
      },
      mapStyle: [
        {
          stylers: [
            { visibility: 'on' },
            { saturation: -100 },
            { gamma: 0.54 },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
        { featureType: 'water', stylers: [{ color: '#423d46' }] },
        {
          featureType: 'poi',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
        { featureType: 'poi', elementType: 'labels.text', stylers: [] },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [{ color: '#edebee' }],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text',
          stylers: [{ visibility: 'off' }],
        },
        { featureType: 'transit.line', stylers: [{ visibility: 'off' }] },
        {
          featureType: 'administrative.neighborhood',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#edebee' }],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [{ gamma: 0.48 }],
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{ gamma: 7.18 }],
        },
      ],
      octopus_ink: {
        position: {
          lat: 43.643487,
          lng: -79.39351,
        },
      },
    };
  },
  methods: {
    toggleInfoWindow(marker) {
      this.infoWindowPos = marker.position;
      this.infoWinOpen = !this.infoWinOpen;
    },
    submit() {
      if (this.form.robot) {
        this.form.sending = true;
        this.contactButtonText = 'Sending...';

        const msg = {
          name: this.form.name,
          email: this.form.email,
          message: `Name: ${this.form.name}\nEmail: ${this.form.email}\nMessage:\n${this.form.message}`,
        };

        axios
          .post('api/mail', msg)
          .then(() => {
            this.contactFormMessage =
              'Thanks for your message! We will get back to you as soon as possible.';
            this.form.sending = false;
            this.contactButtonText = 'Send';
            this.form = {
              robot: false,
              sending: false,
            };
            this.$refs.contactForm.reset();
          })
          .catch((error) => {
            console.log(error);
            this.contactFormMessage =
              'There was an error sending your message. Please try again later.';
            this.form.sending = false;
            this.contactButtonText = 'Send';
          });
      }
    },
    onVerify(response) {
      if (response) this.form.robot = true;
    },
  },
};
</script>

<style>
.gm-style-cc {
  display: none;
}
</style>
