<template>
  <section
    class="main-menu page page--content page--content__very-dark"
    :class="{ 'main-menu--open': menuOpen }"
    aria-label="Main Menu"
    role="navigation"
    aria-hidden="menuOpen ? 'false' : 'true'"
    :aria-expanded="menuOpen ? 'true' : 'false'"
  >
    <GridLines :columns="grid" />
    <div class="wrapper">
      <div class="page--main">
        <nav class="main-menu--nav">
          <ul>
            <li v-for="(item, index) in navigation" :key="index">
              <router-link :to="item.path" @click.native="closeMenu">{{
                item.title
              }}</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
import GridLines from '@/components/GridLines.vue';

export default {
  name: 'SiteNav',
  components: {
    GridLines,
  },
  watch: {
    open(val) {
      if (val) {
        setTimeout(() => {
          this.menuOpen = true;
        }, 10);
      } else {
        this.menuOpen = false;
      }
    },
  },
  props: {
    grid: {
      type: Array,
      default: () => [1, 8, 10, 11, 12],
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const getScrollbarWidth = () =>
      window.innerWidth - document.documentElement.clientWidth;

    document
      .querySelector(':root')
      .style.setProperty('--scrollbar-width', getScrollbarWidth() + 'px');

    const getMenuTogglePosition = () => {
      const toggleDimensions = document
        .querySelector('.nav-toggle')
        .getBoundingClientRect();
      return {
        y: toggleDimensions.top + toggleDimensions.height / 2,
        x: toggleDimensions.left + toggleDimensions.width / 2,
      };
    };
    document
      .querySelector(':root')
      .style.setProperty('--menu-y', getMenuTogglePosition().y + 'px');
    document
      .querySelector(':root')
      .style.setProperty('--menu-x', getMenuTogglePosition().x + 'px');

    window.addEventListener(
      'resize',
      this._debounce(() => {
        document
          .querySelector(':root')
          .style.setProperty('--menu-y', getMenuTogglePosition().y + 'px');
        document
          .querySelector(':root')
          .style.setProperty('--menu-x', getMenuTogglePosition().x + 'px');
      }, 500)
    );
  },
  data() {
    return {
      menuOpen: false,
      navigation: [
        {
          title: 'Strategic Support',
          path: '/services/strategic-support',
        },
        {
          title: 'Our Work',
          path: '/#our-work',
        },
        {
          title: 'About us',
          path: '/#about-us',
        },
        {
          title: 'Blog',
          path: '/blog',
        },
        {
          title: 'The team',
          path: '/#the-team',
        },
        {
          title: 'Marketing Calculator',
          path: '/#marketing-calculator',
        },
        {
          title: 'Media',
          path: '/#media',
        },
        {
          title: 'Contact',
          path: '/#contact',
        },
      ],
    };
  },
  methods: {
    closeMenu() {
      this.menuOpen = false;
      this.$emit('open-menu', false);
    },
  },
};
</script>
