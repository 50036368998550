import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import InlineSvg from 'vue-inline-svg';
import { KinesisContainer, KinesisElement } from 'vue-kinesis';
import * as VueGoogleMaps from 'vue2-google-maps';
import VBurger from 'vue-burger';
import VueGtag from 'vue-gtag';
import GmapMarker from 'vue2-google-maps/src/components/marker';
import VueMoment from 'vue-moment';
import { VueMasonryPlugin } from 'vue-masonry';
import ResponsiveImage from '@/components/ResponsiveImage';

// views
import App from './App';

// global components
Vue.component('inline-svg', InlineSvg);
Vue.component('kinesis-container', KinesisContainer);
Vue.component('kinesis-element', KinesisElement);
Vue.component('responsive-image', ResponsiveImage);

// lodash utilities
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import _toNumber from 'lodash/toNumber';
import _each from 'lodash/each';
import _startCase from 'lodash/startCase';
import _shuffle from 'lodash/shuffle';
import _random from 'lodash/random';

Vue.use(Vuex);

Vue.use(VueGtag, {
  config: { id: 'G-H53MH3XE1C' },
});
import store from './store';
import routes from './routes';

// routes and router
const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to) {
    let position = {};
    if (to.hash) {
      position = {
        selector: to.hash,
      };
    } else {
      position = { x: 0, y: 0 };
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        this.app.disableScrollEffects = false;
        resolve(position);
      }, 100);
    });
  },
});

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VBurger);
Vue.set(Vue.prototype, '_filter', _filter);
Vue.set(Vue.prototype, '_sortBy', _sortBy);
Vue.set(Vue.prototype, '_map', _map);
Vue.set(Vue.prototype, '_debounce', _debounce);
Vue.set(Vue.prototype, '_toNumber', _toNumber);
Vue.set(Vue.prototype, '_each', _each);
Vue.set(Vue.prototype, '_startCase', _startCase);
Vue.set(Vue.prototype, '_shuffle', _shuffle);
Vue.set(Vue.prototype, '_random', _random);
Vue.component('GmapMarker', GmapMarker);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBDpu2BEzbArTe_PSvX1mz0rJ97Do0WY80',
    installComponents: true,
  },
});
Vue.use(VueMoment);
Vue.use(VueMasonryPlugin);

new Vue({
  router,
  store,
  components: { App },
  template: '<App/>',
}).$mount('#app');
